import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormGroup, UntypedFormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { FinalNotes } from "@notes-view/model/notes-model";
import { NoteService } from "@notes-view/services/note.service";
import { Addendum, Cosign } from "@notes/models/notes.model";
import { AugnitoService } from "@notes/services/augnito.service";
import { takeUntil } from "rxjs/operators";
import { QuillEditorComponent } from "@shared-modules/components/quill-editor";
import { Store, select } from "@ngrx/store";
import * as fromNoteStore from "@notes/store";

@Component({
  selector: "cp-note-addendum",
  templateUrl: "./note-addendum.component.html",
  styleUrls: ["./note-addendum.component.scss"],
  host: {
    class: "cp-flex-container cp-h-100",
  },
})
export class NoteAddendumComponent implements OnInit {
  @Input() patientNotesForm: FormGroup;
  private unsubscribe$: Subject<any> = new Subject<any>();

  @ViewChild(QuillEditorComponent)
  editor!: QuillEditorComponent;
  public editorFocussed = 0;

  inDebounce: NodeJS.Timeout;
  constructor(
    private _noteService: NoteService,
    public augnitoService: AugnitoService,
    private _noteStore: Store<fromNoteStore.NoteFormState>
  ) {}

  public notelets$ = this._noteStore.pipe(select(fromNoteStore.getAllNotelets));

  ngOnInit(): void {}

  initSwara() {
    this.augnitoService.augnitoText$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data && this.editor) {
          this.editor.setText(data);
        }
      });
  }

  addendumNote = new UntypedFormControl(null);

  get componentsFormArray() {
    return (this.patientNotesForm.get("components") as FormArray).controls;
  }

  get getMergeNotes(): Addendum[] | Cosign[] {
    const finalNotes = this.patientNotesForm.value as FinalNotes;
    return this._noteService.getAddendumAndCosignMerge(finalNotes);
  }

  setHighlight(highlight: string) {
    this._noteStore.dispatch(fromNoteStore.heighlightText({ highlight }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
