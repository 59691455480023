import { createSelector } from "@ngrx/store";
import * as fromNotesState from "../reducers";
import { noteletsAdaptor } from "../reducers/notelets.reducers";

export const getNotelet = createSelector(
  fromNotesState.getNoteState,
  (state) => state.notelets
);

export const noteletCreateLoaderSelector = createSelector(
  getNotelet,
  (state) => state.createLoading
);

export const noteletDeleteLoaderSelector = createSelector(
  getNotelet,
  (state) => state.deleteLoading
);

export const noteletUpdateLoaderSelector = createSelector(
  getNotelet,
  (state) => state.updateLoading
);

export const noteletCreateErrorSelector = createSelector(
  getNotelet,
  (state) => state.createError
);

export const noteletDeleteOrUpdateErrorSelector = createSelector(
  getNotelet,
  (state) => state.deleteOrUpdateError
);

export const highlightSelector = createSelector(
  getNotelet,
  (state) => state.highlight
);

export const noteletAPISuccess = createSelector(
  getNotelet,
  (state) => state.success
);

export const { selectEntities: getNoteletEntities } =
  noteletsAdaptor.getSelectors(getNotelet);

export const { selectIds: getAllNoteletsIds } =
  noteletsAdaptor.getSelectors(getNotelet);

export const { selectAll: getAllNotelets } =
  noteletsAdaptor.getSelectors(getNotelet);

export const selectEntity = (id) =>
  createSelector(getNoteletEntities, (entities) => entities[id]);
