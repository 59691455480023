import { NoteTypesEffects } from "./note-data-types.effects";
import { NoteContentEffects } from "./note-input-form.effects";
import { NoteDispatchEffects } from "./note-dispatch.effects";
import { NoteletsEffects } from "./notelets.effects";

export const effects: any[] = [
  NoteTypesEffects,
  NoteContentEffects,
  NoteDispatchEffects,
  NoteletsEffects,
];
