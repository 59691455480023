import { OnInit, ViewChild, inject } from "@angular/core";
import { Component } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import { Observable } from "rxjs/Observable";
import { of } from "rxjs/observable/of";
import * as fromNcpSelector from "../../store/selector/ncp.selector";
import * as fromNcpAction from "../../store/action/ncp.action";
import * as fromNcpResetAction from "../../store/action/reset.action";
import { NcpList, NcpResponse } from "../../type/ncp-data.interface";
import moment from "moment-timezone";
import * as fromUserReducer from "src/app/store/reducers/user";
import { PatientService } from "src/app/services/patient.service";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { UtilService } from "src/app/services/util.service";
import { jsPDFDocument } from "jspdf-autotable";
import { robo_medium } from "src/assets/fonts/Roboto-Medium-normal.js";
import { robo_mediumItalic } from "src/assets/fonts/Roboto-MediumItalic-italic.js";
import { robo_light } from "src/assets/fonts/Roboto-Light-normal.js";
import { robo_bold } from "src/assets/fonts/Roboto-Bold-normal.js";
import { displayAge } from "src/app/support-functions/calculateAge";
import { IcuDaysPipe } from "@shared-modules/pipes/icu-days-pipe/icu-days.pipe";
import { DatePipe } from "@angular/common";
import autoTable from "jspdf-autotable";
import { TimeZoneDetails } from "src/app/models/hospital";
import { TimezonePipe } from "@shared-modules/pipes/timezone-pipe/timezone.pipe";
import { TimezoneService } from "src/app/services/timezone.service";
declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");
@Component({
  selector: "app-ncp-view",
  templateUrl: "./ncp-view.component.html",
  styleUrls: ["./ncp-view.component.scss"],
})
export class NcpViewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private unsubscribe$: Subject<void> = new Subject<void>();

  getUser$ = this.store.pipe(select(fromUserReducer.getCurrUser));
  public timeZoneDetail: TimeZoneDetails;
  public ncpFetchLoader$ = this.store.pipe(
    select(fromNcpSelector.getNcpFetchLoader)
  );
  public patient$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData)
  );
  get ncpFetchData$() {
    return this.store.pipe(select(fromNcpSelector.getNcpFetchData));
  }
  displayedRows$: Observable<any>;
  expandedPanelIndex: number;

  public disableBtn = null;
  public patInfo = {} as any;
  public ncpInfo = {} as any;
  imgData = null;
  private _tz = inject(TimezoneService);
  constructor(
    private store: Store<any>,
    public _patientService: PatientService,
    private utils: UtilService,
    private icuDaysPipe: IcuDaysPipe,

    private _timezonePipe: TimezonePipe
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.ncpFetchData$.subscribe((data) => {
        if (data && data[0]?.nursingPlan) {
          this.displayedRows$ = of(this.sort(data));
        }
      });
    }, 500);
    this.patient$.subscribe((res) => {
      // clone it, to avoid modifying the store indirectly
      let data = JSON.parse(JSON.stringify(res));

      if (data && data.CPMRN) {
        this.patInfo = data;
        this.timeZoneDetail = this.patInfo?.hospitalInfo.timeZoneDetail;

        // set image
        this.utils.getDataUri(this.patInfo["hospitalLogo"]).then((info) => {
          this.imgData = info;
        });

        // set no of days
        let noOfDays = Math.ceil(
          (new Date().getTime() -
            new Date(this.patInfo["ICUAdmitDate"]).getTime()) /
            8.64e7
        );
        this.patInfo["noOfDays"] = noOfDays;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  edit(ncp: NcpList[]) {
    this.store.dispatch(
      fromNcpAction.editData({
        data: ncp,
        isEdit: true,
      })
    );
    this.store.dispatch(fromNcpResetAction.resetState());
  }
  //sorting based on author and createed data and time or based on parentID(new diagnosis adding in edit case)
  sort(data: NcpResponse[]) {
    return data[0].nursingPlan.reduce((result, nursingPlan) => {
      const foundIndex = result.findIndex((item) => {
        for (const element of item.plans) {
          if (
            (item.createdBy === nursingPlan.createdBy &&
              element._id === nursingPlan?.groupId) ||
            (item.createdBy === nursingPlan.createdBy &&
              moment(item.createdAt).format("YYYY-MM-DD HH:mm") ==
                moment(nursingPlan.createdAt).format("YYYY-MM-DD HH:mm"))
          ) {
            return true;
          }
        }
        return false;
      });

      if (foundIndex !== -1) {
        result[foundIndex]?.plans.push(nursingPlan);
      } else {
        result.push({
          createdBy: nursingPlan.createdBy,
          createdAt: nursingPlan.createdAt,
          updatedAt: nursingPlan.updatedAt,
          plans: [nursingPlan],
        });
      }
      return result;
    }, []);
  }

  printJsPdf(ncp) {
    this.disableBtn = "print";
    this.ncpInfo = ncp;

    if (ncp) {
      var doc = new jsPDF();
      doc.addFileToVFS("Roboto-MediumItalic.ttf", robo_mediumItalic);
      doc.addFont("Roboto-MediumItalic.ttf", "Roboto-MediumItalic", "normal");
      doc.addFileToVFS("Roboto-Medium-normal.ttf", robo_medium);
      doc.addFont("Roboto-Medium-normal.ttf", "Roboto-Medium", "normal");
      doc.addFileToVFS("Roboto-Light-normal.ttf", robo_light);
      doc.addFont("Roboto-Light-normal.ttf", "Roboto-Light", "normal");
      doc.addFileToVFS("Roboto-Bold-normal.ttf", robo_bold);
      doc.addFont("Roboto-Bold-normal.ttf", "Roboto-Bold", "normal");

      // set properties of the pdf
      let tempMonth = new Date().getMonth() + 1;

      doc.setProperties({
        title:
          this._patientService.displayName(this.patInfo, false) +
          " " +
          this.patInfo["MRN"] +
          " " +
          new Date().getDate() +
          "-" +
          tempMonth +
          "-" +
          new Date().getFullYear(),
        author: "Cloudphysician",
        creator: "Cloudphysician",
      });
      doc = this.setPdfHeader(doc);
      let topY = doc.lastAutoTable.finalY + 8;

      doc = this.setPatientInfo(doc, topY);
      topY = doc.lastAutoTable.finalY + 6;

      //this.setAutoTableHeader(doc, topY, "Nursing Care Plan");
      topY = doc.lastAutoTable.finalY + 4;

      const keys = Object.keys(this.ncpInfo.plans[0].fields);
      const dummycolumn = ["S.No.", "Diagnosis", ...keys];
      const capitalizedKeys = this.capitalizeParam(dummycolumn);
      const columns = [capitalizedKeys];

      const row = this.ncpInfo.plans.map((plan, index) => {
        const rowData = [index + 1, plan.diagnose];
        const fieldKeys = Object.keys(plan.fields);
        fieldKeys.forEach((key) => {
          rowData.push(plan.fields[key]);
        });
        return rowData;
      });
      const data = row;

      const Options = this.callbackToSetNumberOfColumns(dummycolumn, doc);
      autoTable(doc, {
        tableLineWidth: 0.1,
        tableWidth: "auto",
        theme: "plain",
        headStyles: {
          fillColor: "#F4F7FE",
          textColor: [0, 0, 0],
          halign: "left",
          valign: "middle",
          lineWidth: 0.1,
          lineColor: "#F6F7F9",
        },
        willDrawCell: (data) => {
          // Set the border color for the bottom border

          doc.setLineWidth(0.1);

          doc.setDrawColor("#CDD7EB");
          doc.setFontSize(8);

          // doc.line(
          //   data.cell.x, // Left edge of the cell
          //   data.cell.y, // Bottom edge of the cell
          //   data.cell.x + data.cell.width, // Right edge of the cell
          //   data.cell.y
          // );
        },
        columnStyles: Options["columnStyles"],
        //html: htmlId,

        startY: topY,
        margin: { top: 65, bottom: 25 },
        styles: { overflow: "linebreak", fontSize: 8, halign: "left" },

        head: columns,
        body: data,
      });

      doc = this.setPdfHeaderAndFooterOnEveryPage(doc);

      // open the pdf in new window
      let pdfWindow = window.open("", "", "width=700,height=900");
      pdfWindow.document.write(
        "<iframe style='width: 100%; height: 70rem' src='" +
          doc.output("datauristring") +
          "' frameborder='0'></iframe>"
      );
      pdfWindow.document.close();
      this.disableBtn = null;
    } else {
      this.disableBtn = null;
    }
  }

  setPdfHeader(doc: jsPDFDocument): jsPDFDocument {
    doc.autoTable({
      startY: 14,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: "#2B2A3A",
      },
      head: [["Nursing Care Plan"]],
      styles: { halign: "center", fontSize: 14, fontStyle: "bold" },
    });

    // IMAGE
    if (this.imgData) {
      doc.addImage(this.imgData, "JPEG", 15, 8, 20, 20);
    }

    let topY = doc.lastAutoTable.finalY + 8;

    if (doc.getNumberOfPages() > 1) {
      //set patient info
      doc.autoTable({
        tableLineWidth: 0.1,
        startY: topY,
        headStyles: {
          fillColor: "#E2E8F4",
          textColor: [0, 0, 0],
          hLineWidth: 0.1, // Add border to left and right sides of the header
          vLineColor: "#CDD7EB", // Set vertical border color
          hLineColor: "#CDD7EB", // Set horizontal border color
        },
        head: [["Patient Information"]],
        styles: { fontSize: 12 },
      });
      doc.setDrawColor("#fff"); // Set line color to white
      doc.setLineWidth(0.1); // Set line width to match the table border width
      doc.line(14, topY, 196, topY); // Adjust the coordinates as needed
      doc.autoTable({
        styles: {
          fontSize: 10,
          textColor: "#2B2A3A",
          cellBorderStyle: "none",

          hLineWidth: 0.1,
          hLineColor: "#CDD7EB", // Set horizontal border color
          vLineColor: "#CDD7EB", // Set vertical border color },
        },
        tableLineWidth: 0.1,
        tableWidth: "auto",
        startY: topY + 8,
        columnStyles: {
          0: { cellWidth: 33, fillColor: "#fff" },
          1: { cellWidth: 58, fillColor: "#fff" },
          2: { cellWidth: 33, fillColor: "#fff" },
          3: { cellWidth: 58, fillColor: "#fff" },
        },
        body: [
          [
            { content: "Name: ", styles: { font: "Roboto-Medium" } },
            this.setPatientNameInCamelCase(
              this.patInfo["name"],
              this.patInfo["lastName"]
            ) || "-",
            { content: "MRN: ", styles: { font: "Roboto-Medium" } },
            `${this.patInfo["MRN"] || "-"}`,
          ],
          [
            { content: "Age / Gender: ", styles: { font: "Roboto-Medium" } },
            `${this.getPatientAge() + " / " + (this.patInfo["sex"] || "-")}`,

            { content: "Bed no.: ", styles: { font: "Roboto-Medium" } },
            `${this.patInfo["bedNo"] || "-"}`,
          ],
        ],
      });
    }
    // Draw a line to cover the top border of the head
    const headHeight = doc.previousAutoTable.finalY - topY;
    doc.setDrawColor("#fff"); // Set line color to white
    doc.setLineWidth(0.8); // Set line width to match the table border width
    doc.line(14.5, topY + 8.3, 195.5, topY + 8.3); // Adjust the coordinates as needed
    return doc;
  }

  setPatientNameInCamelCase(name, lastName) {
    if (name.length && name.startsWith("b/o"))
      return `${name}` + `${lastName ?? ""}`;

    return (
      `${name ? name[0].toUpperCase() + name.substr(1) : ""}` +
      `${lastName ? " " + lastName[0].toUpperCase() + lastName.substr(1) : ""}`
    );
  }

  getPatientAge() {
    return displayAge(
      this.patInfo?.age,
      true,
      this.patInfo.patientType,
      this.patInfo.dob
    );
  }

  setPatientInfo(doc: jsPDFDocument, topY): jsPDFDocument {
    doc.autoTable({
      tableLineWidth: 0.1,
      startY: topY,
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: [0, 0, 0],
        hLineWidth: 0.1, // Add border to left and right sides of the header
        vLineColor: "#CDD7EB", // Set vertical border color
        hLineColor: "#CDD7EB", // Set horizontal border color
      },
      head: [["Patient Information"]],
      styles: { fontSize: 12 },
    });
    // Draw a line to cover the top border of the head

    doc.setDrawColor("#fff"); // Set line color to white
    doc.setLineWidth(0.1); // Set line width to match the table border width
    doc.line(14, topY, 196, topY); // Adjust the coordinates as needed
    //doc.line(14.5, topY + 7.5, 195.5, topY + 7.5); // Adjust the coordinates as needed
    const noOfIcuDays = this.patInfo.ICUAdmitDate
      ? this.icuDaysPipe.transform(
          this.patInfo.ICUAdmitDate,
          !this.patInfo?.isCurrentlyAdmitted && this.patInfo?.ICUDischargeDate
        )
      : null;

    const admitDate = this.patInfo.ICUAdmitDate
      ? this._timezonePipe.transform(
          this.patInfo.ICUAdmitDate,
          "MMM D Y, HH:mm"
        )
      : "";
    const ncpCreatedDate = this.ncpInfo?.createdAt
      ? this._timezonePipe.transform(this.ncpInfo?.createdAt, "MMM D Y, HH:mm")
      : "";
    const ncpUpdatedDate = this.ncpInfo?.updatedAt
      ? this._timezonePipe.transform(this.ncpInfo?.updatedAt, "MMM D Y, HH:mm")
      : "";

    doc.autoTable({
      styles: {
        fontSize: 10,
        textColor: "#2B2A3A",
        cellBorderStyle: "none",

        hLineWidth: 0.1,
        hLineColor: "#CDD7EB", // Set horizontal border color
        vLineColor: "#CDD7EB", // Set vertical border color
      },

      // Set table border width to a non-zero value (e.g., 0.5)
      tableLineWidth: 0.1,
      tableWidth: "auto",
      startY: topY + 8,
      columnStyles: {
        0: { cellWidth: 33, fillColor: "#fff" },
        1: { cellWidth: 58, fillColor: "#fff" },
        2: { cellWidth: 33, fillColor: "#fff" },
        3: { cellWidth: 58, fillColor: "#fff" },
      },
      body: [
        [
          { content: "Name: ", styles: { font: "Roboto-Medium" } },
          //  setFontWeight(doc, "bold"),
          this.setPatientNameInCamelCase(
            this.patInfo["name"],
            this.patInfo["lastName"]
          ) || "-",
          { content: "MRN: ", styles: { font: "Roboto-Medium" } },
          `${this.patInfo["MRN"] || "-"}`,
        ],
        [
          { content: "Age / Gender: ", styles: { font: "Roboto-Medium" } },
          `${this.getPatientAge() + " / " + (this.patInfo["sex"] || "-")}`,

          { content: "Unit / Bed no.: ", styles: { font: "Roboto-Medium" } },
          `${
            (this.patInfo["unitName"] || "-") +
            " / " +
            (this.patInfo["bedNo"] || "-")
          }`,
        ],

        [
          { content: "Height / Weight: ", styles: { font: "Roboto-Medium" } },
          `${
            (this.patInfo["height"]
              ? this.getRounded(this.patInfo.height, 100) + "cm"
              : "-") +
            " / " +
            (this.patInfo.weight
              ? this.getRounded(this.patInfo.weight, 100) + "kg"
              : "-")
          }`,

          { content: "Admit date: ", styles: { font: "Roboto-Medium" } },
          `${admitDate || "-"} (${this.timeZoneDetail?.abbreviation})`,
        ],
        [
          { content: "Blood Group: ", styles: { font: "Roboto-Medium" } },
          `${this.patInfo["bloodGroup"] || "-"}`,
          "BMI: ",
          `${this.patInfo?.BMI?.toFixed(2) || "-"}`,
        ],
        [
          { content: "Allergies: ", styles: { font: "Roboto-Medium" } },
          `${this.patInfo["allergies"] || "-"}`,

          { content: "No of days in ICU: ", styles: { font: "Roboto-Medium" } },
          `${noOfIcuDays || "-"}`,
        ],
        [
          { content: "Primary MD: ", styles: { font: "Roboto-Medium" } },
          `${
            this.patInfo["PCP"]
              ? this.patInfo["PCP"] +
                (this.patInfo["PCP_speciality"]
                  ? "," + " " + this.patInfo["PCP_speciality"]
                  : "")
              : "-"
          } `,
          { content: "Author name: ", styles: { font: "Roboto-Medium" } },
          `${this.ncpInfo["createdBy"] || "-"}`,
        ],
        [
          { content: "Created date: ", styles: { font: "Roboto-Medium" } },
          `${ncpCreatedDate || "-"}  (${this.timeZoneDetail?.abbreviation})`,

          {
            content: "Last updated date: ",
            styles: { font: "Roboto-Medium" },
          },
          `${ncpUpdatedDate || "-"}  (${this.timeZoneDetail?.abbreviation})`,
        ],
      ],
    });

    // Draw a line to cover the top border of the head
    const headHeight = doc.previousAutoTable.finalY - topY;
    doc.setDrawColor("#fff"); // Set line color to white
    doc.setLineWidth(0.8); // Set line width to match the table border width
    doc.line(14.5, topY + 8.3, 195.5, topY + 8.3); // Adjust the coordinates as needed
    return doc;
  }

  getRounded(val, digits) {
    return Math.round(val * digits) / digits;
  }

  setPdfHeaderAndFooterOnEveryPage(doc: jsPDFDocument): jsPDFDocument {
    const pageCount = doc.internal.getNumberOfPages();

    for (let i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      doc.line(8, 323 - 36, 202, 323 - 36);
      doc.setFontSize(10);
      doc.setFont("Roboto-Light", "normal");
      // left side content
      doc.text(
        "Page " +
          String(i) +
          " of " +
          String(pageCount) +
          ", " +
          "Report created on RADAR",
        10,
        323 - 30
      );

      // right side content
      let printDate = this.setDateTime();
      printDate += this.timeZoneDetail
        ? ` ( ${this.timeZoneDetail?.abbreviation})`
        : "";
      doc.text("Printed on: " + printDate, 210 - 65, 323 - 30);
      // set header
      if (i > 1) doc = this.setPdfHeader(doc);

      // set footer
      // draw the line
    }

    return doc;
  }

  setDateTime(dateStr = "") {
    let noteDayObj = this._tz.getCurrentTimeObj();

    if (dateStr) {
      noteDayObj = this._tz.transformIntoTimezoneObj(dateStr);
    }
    let noteMonth = noteDayObj.month() + 1;
    let noteDate =
      (noteDayObj.date() > 9 ? "" : "0") +
      noteDayObj.date() +
      "-" +
      (noteMonth > 9 ? "" : "0") +
      noteMonth +
      "-" +
      noteDayObj.year() +
      " " +
      (noteDayObj.hour() > 9 ? "" : "0") +
      noteDayObj.hour() +
      ":" +
      (noteDayObj.minute() > 9 ? "" : "0") +
      noteDayObj.minute();

    return noteDate;
  }

  setAutoTableHeader(doc, topY, headerText, left = null, right = null) {
    doc.autoTable({
      startY: topY,
      headStyles: {
        fillColor: "#EEEFF4",
        textColor: [0, 0, 0],
        lineColor: "#E2E8F4",
      },
      head: [[headerText]],
      margin: { top: 65, bottom: 25, left: left, right: right },
      styles: { fontSize: 8, halign: "left", valign: "center" },
    });

    // doc.setDrawColor("red"); // Set line color to white
    // doc.setLineWidth(0.2); // Set line width to match the table border width
    // doc.line(14, topY + 10.6, 196, topY + 10.6); // Adjust the coordinates as needed
  }

  callbackToSetNumberOfColumns = (columns, doc: jsPDFDocument) => {
    let Options = {};

    const columnLength = !!columns.length ? columns.length : 0;

    const remainingWidth = 167 / (columnLength - 1);
    Options["columnStyles"] = {
      0: {
        cellWidth: 15,
        overflow: "linebreak",
        fillColor: "#FFF",
        lineWidth: 0.1,
        lineColor: "#F6F7F9",
        font: "Roboto-Light",
        textColor: [0, 0, 0],
      },
    };

    if (columnLength)
      for (let i = 1; i <= columnLength; i++) {
        let index = `${i}`;
        Options["columnStyles"][index] = {
          cellWidth: remainingWidth,
          overflow: "linebreak",
          fillColor: "#FFF",
          lineWidth: 0.1,
          lineColor: "#F6F7F9",
          font: "Roboto-Light",
        };
      }
    return Options;
  };

  capitalizeParam(value) {
    let transformedArray = value?.map((key) => {
      if (key === "goalsandoutcomes") {
        return "Goals and Outcomes";
      } else {
        return key
          .replace(/([A-Z])/g, " $1")
          .trim()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }
    });
    return transformedArray;
  }
}
