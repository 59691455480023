import { createAction, props } from "@ngrx/store";
import { CreateNoteletPayloadType } from "@notes/models/notes.model";
import { NoteletInterface } from "@shared-modules/components/quill-editor";

export const LOAD_NOTE_NOTELET = "[NOTE-INPUT-FORM-DATA] load note notelet";
export const LOAD_NOTE_NOTELET_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] load note notelet success";
export const LOAD_NOTE_NOTELET_ERROR =
  "[NOTE-INPUT-FORM-DATA] load note notelet error";

export const CREATE_NOTELET = "[NOTE-INPUT-FORM-DATA] create note notelet";
export const CREATE_NOTELET_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] create note notelet success";
export const CREATE_NOTELET_ERROR =
  "[NOTE-INPUT-FORM-DATA] create note notelet error";

export const UPDATE_NOTELET = "[NOTE-INPUT-FORM-DATA] udpate note notelet";
export const UPDATE_NOTELET_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] udpate note notelet success";
export const UPDATE_NOTELET_ERROR =
  "[NOTE-INPUT-FORM-DATA] udpate note notelet error";

export const DELETE_NOTELET = "[NOTE-INPUT-FORM-DATA] delete note notelet";
export const DELETE_NOTELET_SUCCESS =
  "[NOTE-INPUT-FORM-DATA] delete note notelet success";
export const DELETE_NOTELET_ERROR =
  "[NOTE-INPUT-FORM-DATA] delete note notelet error";

export const HIGHLIGHT_TEXT = "[NOTE-INPUT-FORM-DATA] highlight text";

export const loadNotelets = createAction(LOAD_NOTE_NOTELET);
export const loadNoteletSuccess = createAction(
  LOAD_NOTE_NOTELET_SUCCESS,
  props<{ notelets: NoteletInterface[] }>()
);
export const loadNoteletError = createAction(
  LOAD_NOTE_NOTELET_ERROR,
  props<{ error: string }>()
);

export const createNotelet = createAction(
  CREATE_NOTELET,
  props<{ payload: CreateNoteletPayloadType }>()
);
export const createNoteletSuccess = createAction(
  CREATE_NOTELET_SUCCESS,
  props<{ notelet: NoteletInterface }>()
);
export const createNoteletError = createAction(
  CREATE_NOTELET_ERROR,
  props<{ error: string }>()
);

export const updateNotelet = createAction(
  UPDATE_NOTELET,
  props<{ template: string; id: string }>()
);
export const updateNoteletSuccess = createAction(
  UPDATE_NOTELET_SUCCESS,
  props<{ notelet: NoteletInterface }>()
);
export const updateNoteletError = createAction(
  UPDATE_NOTELET_ERROR,
  props<{ error: string }>()
);

export const heighlightText = createAction(
  HIGHLIGHT_TEXT,
  props<{ highlight: string }>()
);

export const deleteNotelet = createAction(
  DELETE_NOTELET,
  props<{ id: string }>()
);
export const deleteNoteletSuccess = createAction(
  DELETE_NOTELET_SUCCESS,
  props<{ id: string }>()
);
export const deleteNoteletError = createAction(
  DELETE_NOTELET_ERROR,
  props<{ error: string }>()
);
