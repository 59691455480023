import { FormControl } from "@angular/forms";

// Validation of date range
export const isTemplateModifiedValidator = (template: string) => {
  return (control: FormControl) => {
    if (control.value == template) {
      return { isSameTemplate: true };
    }
    return null;
  };
};
