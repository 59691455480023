import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as FromNoteletsActions from "../actions/notelet.actions";
import { NoteletInterface } from "@shared-modules/components/quill-editor";

export interface NoteletState extends EntityState<NoteletInterface> {
  success: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  updateLoading: boolean;
  deleteOrUpdateError: string;
  createError: string;
  highlight: string;
}

export const noteletsAdaptor = createEntityAdapter<NoteletInterface>({
  selectId: selectNoteId,
});

export const initialState: NoteletState = noteletsAdaptor.getInitialState({
  success: false,
  createLoading: false,
  deleteLoading: false,
  updateLoading: false,
  deleteOrUpdateError: "",
  createError: "",
  highlight: "",
});

export function selectNoteId(a: NoteletInterface): string {
  return a._id;
}

const _noteletsReducer = createReducer(
  initialState,
  on(FromNoteletsActions.loadNoteletSuccess, (state, { notelets }) =>
    noteletsAdaptor.setAll(notelets, state)
  ),
  on(FromNoteletsActions.createNotelet, (state) => ({
    ...state,
    success: false,
    createLoading: true,
  })),
  on(FromNoteletsActions.createNoteletSuccess, (state, { notelet }) =>
    noteletsAdaptor.addOne(notelet, {
      ...state,
      success: true,
      createLoading: false,
    })
  ),
  on(FromNoteletsActions.createNoteletError, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
  })),
  on(FromNoteletsActions.updateNotelet, (state) => ({
    ...state,
    success: false,
    updateLoading: true,
  })),
  on(FromNoteletsActions.updateNoteletSuccess, (state, { notelet }) =>
    noteletsAdaptor.setOne(notelet, {
      ...state,
      success: true,
      updateLoading: false,
    })
  ),
  on(FromNoteletsActions.updateNoteletError, (state, { error }) => ({
    ...state,
    updateLoading: false,
    deleteOrUpdateError: error,
  })),
  on(FromNoteletsActions.deleteNotelet, (state) => ({
    ...state,
    success: false,
    deleteLoading: true,
  })),
  on(FromNoteletsActions.deleteNoteletSuccess, (state, { id }) =>
    noteletsAdaptor.removeOne(id, {
      ...state,
      success: true,
      deleteLoading: false,
    })
  ),
  on(FromNoteletsActions.deleteNoteletError, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteOrUpdateError: error,
  })),
  on(FromNoteletsActions.heighlightText, (state, { highlight }) => ({
    ...state,
    updateLoading: false,
    highlight,
  }))
);

export function reducer(state: NoteletState, action: Action) {
  return _noteletsReducer(state, action);
}
