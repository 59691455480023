import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormGroup, UntypedFormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { FinalNotes } from "@notes-view/model/notes-model";
import { NoteService } from "@notes-view/services/note.service";
import { Store, select } from "@ngrx/store";
import * as fromNoteStore from "@notes/store";

@Component({
  selector: "cp-note-attest",
  templateUrl: "./note-attest.component.html",
  styleUrls: ["./note-attest.component.scss"],
  host: {
    class: "cp-flex-container cp-h-100",
  },
})
export class NoteAttestComponent implements OnInit {
  @Input() patientNotesForm: FormGroup;
  private unsubscribe$: Subject<any> = new Subject<any>();

  public notelets$ = this._noteStore.pipe(select(fromNoteStore.getAllNotelets));

  inDebounce: NodeJS.Timeout;
  constructor(
    private _noteService: NoteService,
    private _noteStore: Store<fromNoteStore.NoteFormState>
  ) {}

  ngOnInit(): void {
    this.noteletsInit();
  }

  attestationStatusMsg = new UntypedFormControl(null);

  get componentsFormArray() {
    return (this.patientNotesForm.get("components") as FormArray).controls;
  }

  get getMergeNotes(): any[] {
    const finalNotes = this.patientNotesForm.value as FinalNotes;
    return this._noteService.getAddendumAndCosignMerge(finalNotes);
  }

  setHighlight(highlight: string) {
    this._noteStore.dispatch(fromNoteStore.heighlightText({ highlight }));
  }

  noteletsInit() {
    this._noteStore.dispatch(fromNoteStore.loadNotelets());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
