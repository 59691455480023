<div [formGroup]="patientNotesForm" class="note-footer">
  <div class="note-footer--error-section">
    <div>
      <h6>
        <!-- <i>Last saved at: {{ lastUpdatedAt | date: "dd-MM-yyyy HH:mm:ss" }}</i> -->
      </h6>
    </div>
    <!-- <div *ngIf="!!disableBtn()" class="error-text">{{ ERROR_MSG }}</div> -->
  </div>
  <ng-container *ngIf="(noteCTA$ | async) != CTAType.Attest">
    <div class="note-footer--buttons">
      <div class="display-flex gap-1">
        <div
          class="note-footer--buttons__delete"
          *ngTemplateOutlet="deleteButtonTemplate"
        ></div>
        <div>
          <cp-save-template></cp-save-template>
        </div>
      </div>
      <div class="display-flex gap-1">
        <div class="note-footer--buttons__item">
          <button
            mat-flat-button
            class="button-secondary"
            (click)="onSubmit(SubmitTypes.Pend)"
            [disabled]="
              (getLoaderState$(LoaderNames.PEND_NOTE_LOADER) | async) ||
              (disableActionButtons && (noteCTA$ | async) == CTAType.New)
            "
          >
            Pend<app-loader
              [buttonLoader]="true"
              *ngIf="getLoaderState$(LoaderNames.PEND_NOTE_LOADER) | async"
            ></app-loader>
          </button>
        </div>
        <div
          class="note-footer--buttons__item"
          *ngxPermissionsOnly="['attest_notes']"
        >
          <button
            *ngIf="
              attestRequired
                .get(currentUser.role)
                .includes(patientNotesForm.get('noteType').value)
            "
            mat-flat-button
            class="button-secondary"
            position="top-center"
            popoverTrigger="hover"
            cpPopover="You automatically sign the note when you request for an attestation"
            (click)="onRequestAttest()"
            [disabled]="
              (getLoaderState$(LoaderNames.REQUEST_ATTESTATION_NOTE_LOADER)
                | async) ||
              (disableActionButtons && (noteCTA$ | async) == CTAType.New)
            "
          >
            Request attest<app-loader
              [buttonLoader]="true"
              *ngIf="
                getLoaderState$(LoaderNames.REQUEST_ATTESTATION_NOTE_LOADER)
                  | async
              "
            ></app-loader>
          </button>
        </div>
        <div *ngIf="signPermission$ | async" class="note-footer--buttons__item">
          <button
            mat-flat-button
            class="button-primary"
            (click)="onSubmit(SubmitTypes.Sign)"
            [disabled]="
              (getLoaderState$(LoaderNames.SIGN_NOTE_LOADER) | async) ||
              (disableActionButtons && (noteCTA$ | async) == CTAType.New)
            "
          >
            Sign<app-loader
              [buttonLoader]="true"
              *ngIf="getLoaderState$(LoaderNames.SIGN_NOTE_LOADER) | async"
            ></app-loader>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(noteCTA$ | async) == CTAType.Attest">
    <div class="note-footer--buttons">
      <div class="display-flex gap-1">
        <div
          class="note-footer--buttons__delete"
          *ngTemplateOutlet="deleteButtonTemplate"
        ></div>
        <div>
          <cp-save-template></cp-save-template>
        </div>
      </div>
      <div class="display-flex gap-1">
        <div class="note-footer--buttons__item">
          <button
            mat-flat-button
            class="button-primary reject mr-1"
            (click)="onAttest(CosignTypes.Rejected)"
            [disabled]="
              (getLoaderState$(LoaderNames.ATTEST_REJECTED_LOADER) | async) ||
              !attestationStatusMsg?.value
            "
          >
            Reject<app-loader
              [buttonLoader]="true"
              *ngIf="
                getLoaderState$(LoaderNames.ATTEST_REJECTED_LOADER) | async
              "
            ></app-loader>
          </button>
        </div>
        <div class="note-footer--buttons__item">
          <button
            mat-flat-button
            (click)="onAttest(CosignTypes.Accepted)"
            [disabled]="
              getLoaderState$(LoaderNames.ATTEST_ACCEPTED_LOADER) | async
            "
            class="button-primary"
          >
            Attest<app-loader
              [buttonLoader]="true"
              *ngIf="
                getLoaderState$(LoaderNames.ATTEST_ACCEPTED_LOADER) | async
              "
            ></app-loader>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <div style="height: 4rem">
    <!-- the place holder for quill toolbar -->
  </div>
</div>
<ng-template #deleteButtonTemplate>
  <div class="cp-mr-auto cp-ml-1">
    <button mat-flat-button class="button-tertiary" (click)="onDeleteClick()">
      Delete
    </button>
  </div>
</ng-template>

<ng-template #deleteConfirmationTemplate>
  <section class="iris-modal-container cc-status-wrapper">
    <div mat-dialog-title class="title">
      <mat-icon>warning</mat-icon>
      Are you sure you want to delete this note ?
    </div>
    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close class="button-secondary">
        Cancel
      </button>
      <button mat-flat-button [mat-dialog-close]="true" class="button-primary">
        Yes, Submit
      </button>
    </mat-dialog-actions>
  </section>
</ng-template>
