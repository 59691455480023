import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  inject,
} from "@angular/core";
import { FormArray, FormGroup, UntypedFormControl } from "@angular/forms";
import { NoteFormsService } from "@notes/services/note-forms.service";
import { Subject, combineLatest } from "rxjs";
import * as fromNoteStore from "@notes/store";
import { map, take, takeUntil } from "rxjs/operators";
import { AsyncPipe } from "@angular/common";
import { AutosaveService } from "@notes/services/autosave.service";
import { Store, select } from "@ngrx/store";
import { CTAType } from "@notes/models/notes.model";
import { template } from "@notes/utils/templates";
import { CoreHttpService } from "src/app/services/base-service/core-http.service";
import { CustomRouterState } from "src/app/store/router/custom-serialize";
import { AugnitoService } from "@notes/services/augnito.service";
import { getTemplatesStyles } from "@notes/utils/template.config";
import { memoize } from "lodash";
import { createRoot } from "react-dom/client";
import React from "react";
import IcdNotesWrapper from "src/app/react/sharedComponents/IcdNotesModal";
import App from "src/app/react/App";
import store from "src/app/react/reactStore/reduxStore";
import { icdShowStatusState } from "src/app/react/reactStore/reactSlice/icdNotesSlice";
import {
  AutocompleteService,
  QuillEditorComponent,
} from "@shared-modules/components/quill-editor";
import { NoteService } from "@notes/services/note.service";

@Component({
  selector: "cp-note-editor",
  templateUrl: "./note-editor.component.html",
  styleUrls: ["./note-editor.component.scss"],
  providers: [AsyncPipe],
  host: {
    class: "cp-flex-container cp-h-100",
  },
})
export class NoteEditorComponent
  extends CoreHttpService
  implements OnInit, OnChanges
{
  @Input() patientNotesForm: FormGroup;
  private unsubscribe$: Subject<any> = new Subject<any>();

  @ViewChildren(QuillEditorComponent)
  editor!: QueryList<QuillEditorComponent>;
  public editorFocussed: number | null = null;

  @ViewChild("icdModalPopUp", { static: true }) icdModalPopUp: ElementRef;

  @Input() isHospitalNoteChargeable: boolean;

  memoizedGetTemplatesStyles = memoize(getTemplatesStyles);
  public highlight: string = "";
  inDebounce: NodeJS.Timeout;

  public checkCosignMessage$ = this._noteStore.pipe(
    select(fromNoteStore.checkCosignMessage),
    take(1)
  );

  public notelets$ = this._noteStore.pipe(select(fromNoteStore.getAllNotelets));

  CTAType = CTAType;
  attestationStatusMsg = new UntypedFormControl(null);
  CPMRN: string;
  encounters: string;

  public noteCTA$ = this._noteStore.pipe(
    takeUntil(this.unsubscribe$),
    select(fromNoteStore.getCTA),
    map((data) => data.cta)
  );

  setICDReviewStatus: boolean = false;
  constructor(
    private _noteFormService: NoteFormsService,
    private _autoSaveService: AutosaveService,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    public augnitoService: AugnitoService,
    private _autocompleteService: AutocompleteService,
    _routeStore: Store<CustomRouterState>
  ) {
    super(_routeStore);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkICDStatus();
  }

  ngOnInit(): void {
    this.CPMRN = this.params?.patientCPMRN;
    this.encounters = this.params?.patientEncounters;

    this.autoSaveOnPopUpClose();
    this.initSwara();
    this.checkForAttestationMessage();
    this.noteletsInit();
  }

  noteletsInit() {
    this._noteStore.dispatch(fromNoteStore.loadNotelets());
  }

  setHighlight(highlight: string) {
    this._noteStore.dispatch(fromNoteStore.heighlightText({ highlight }));
  }

  checkICDStatus() {
    const componentsArray = this.patientNotesForm.get(
      "components"
    ) as FormArray;

    if (componentsArray) {
      setTimeout(() => {
        componentsArray.controls.forEach((group: FormGroup) => {
          group.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((componentData) => {
              store.dispatch(icdShowStatusState(true));
            });
        });
      });
    }
  }

  showICD() {
    const root = createRoot(this.icdModalPopUp.nativeElement);
    root.render(
      React.createElement(App, {
        onClose: () => {
          root.unmount();
        },
        showModalType: "ICD",
      })
    );
  }

  checkForAttestationMessage() {
    this.checkCosignMessage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((physicianMessage) => {
        if (physicianMessage)
          this.attestationStatusMsg.setValue(physicianMessage);
      });
  }

  initSwara() {
    this.augnitoService.augnitoText$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (this.editorFocussed === null) return;
        const instance = this.editor.toArray()[this.editorFocussed];
        if (data && instance) {
          instance.setText(data);
        }
      });
  }

  public disableActionButtons = true;
  dispatchAutosave() {
    this.disableActionButtons = false;
    setTimeout(() => {
      this._autoSaveService.handleAutoSave(this.patientNotesForm);
    }, 500);
  }

  private autoSaveOnPopUpClose(): void {
    combineLatest([
      this._noteFormService.popUpCloseTrigger$,
      this._noteStore.pipe(select(fromNoteStore.isInitialState)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([autosave, isInitialState]) => {
        if (autosave && !isInitialState) {
          this._autoSaveService.handleAutoSave(this.patientNotesForm);
        }
      });
  }

  get componentsFormArray() {
    return (this.patientNotesForm.get("components") as FormArray).controls;
  }

  get showImpactCases() {
    return this._noteFormService.showImpactCases(this.patientNotesForm);
  }

  get editorClass() {
    return (this.patientNotesForm.get("components") as FormArray).length === 1;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTemplateHeightClass(displayName: string) {
    const { noteType, noteSubType } = this.patientNotesForm.value;
    const template = this.memoizedGetTemplatesStyles(noteType, noteSubType);
    const matchedTemplate = template.find(
      (t) => t.templateDisplayName === displayName
    );

    return matchedTemplate ? { [matchedTemplate.height]: true } : {};
  }

  get addendumArrays() {
    return (this.patientNotesForm.get("addendum") as FormArray).controls;
  }

  get checkProgressOrEventNote() {
    // we will get this out
    const formNoteType = this.patientNotesForm.get("noteType").value;
    return (
      formNoteType === "Progress" ||
      formNoteType === "Event" ||
      formNoteType == "Consult note"
    );
  }

  get showText() {
    return store.getState()?.icdCodes?.icdShowStatusState
      ? "Review ICD"
      : "Show ICD";
  }

  showReviewICD() {
    const icdReviewState = store.getState().icdCodes.icdShowStatusState;
    icdReviewState ? this.reviewICD() : this.showICD();
  }

  reviewICD() {
    const noteData = this.patientNotesForm.value;
    const root = createRoot(this.icdModalPopUp.nativeElement);
    root.render(
      React.createElement(IcdNotesWrapper, {
        noteData,
        CPMRN: this.CPMRN,
        encounters: this.encounters,
        onClose: () => {
          root.unmount();
        },
      })
    );
  }

  disclaimer() {
    this.disableActionButtons = false; // sumbit button enabled

    const componentsArray = this.patientNotesForm.get(
      "components"
    ) as FormArray;
    const firstComponentGroup = componentsArray.at(0) as FormGroup;
    const valueControl = firstComponentGroup.get("value");
    const currentValue = valueControl.value;
    const disclaimerText = template.get("disclaimer");
    if (!currentValue.includes(disclaimerText)) {
      const updatedValue = currentValue + disclaimerText;
      valueControl.setValue(updatedValue);
    }
  }

  scrollContainer() {
    this._autocompleteService.resetAutocomplete();
  }
}
