import { FormControl } from "@angular/forms";
import { NoteletInterface } from "@shared-modules/components/quill-editor";

// Validation of date range
export const noteletsNameValidator = (notelets: NoteletInterface[]) => {
  return (control: FormControl) => {
    if (notelets.some((notelet) => notelet.key === control.value)) {
      return { duplicateNotelets: true };
    }
    return null;
  };
};
