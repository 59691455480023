<div class="template-form">
  <div class="template-form__body">
    <mat-tab-group>
      <mat-tab label="Create notelets">
        <ng-container
          [ngTemplateOutlet]="formTemplate"
          [ngTemplateOutletContext]="{ form: createForm, isUpdate: false }"
        >
        </ng-container>
      </mat-tab>
      <mat-tab label="Edit notelets">
        <ng-container
          [ngTemplateOutlet]="formTemplate"
          [ngTemplateOutletContext]="{ form: updateForm, isUpdate: true }"
        >
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-template #formTemplate let-form="form" let-isUpdate="isUpdate">
    <form [formGroup]="form" class="cp-mt-2">
      <mat-form-field *ngIf="isUpdate; else inputText">
        <mat-label>Select notelets</mat-label>
        <cp-dropdown
          [data]="templates | noteletsDropdown"
          placeholder="Select a notelets"
          [multiple]="false"
          formControlName="key"
        ></cp-dropdown>
      </mat-form-field>
      <ng-template #inputText>
        <div [ngClass]="{ 'cp-mb-2': !isUpdate }">
          <mat-form-field class="template-form__name" hideRequiredMarker="true">
            <mat-label>Name*</mat-label>
            <input
              matInput
              placeholder="Name"
              (input)="avoidSpaces($event)"
              formControlName="key"
            />
            <mat-hint>Note: you can't have spaces in notelets name</mat-hint>
            <mat-error *ngIf="createForm.get('key')?.hasError('required')"
              >This field is required</mat-error
            >
            <mat-error
              *ngIf="createForm.get('key')?.hasError('duplicateNotelets')"
              >Cannot create duplicate notelets</mat-error
            >
          </mat-form-field>
        </div>
      </ng-template>
      <cp-quill-editor
        class="template-form__body cp-quill-notelet"
        formControlName="value"
        [autocompleteType]="autocompleteType"
      ></cp-quill-editor>
    </form>
    <div class="cp-mt-2">
      <span *ngIf="(createError$ | async) && !isUpdate" class="error-text">{{
        createError$ | async
      }}</span>

      <span
        *ngIf="(udpateOrDeleteError$ | async) && isUpdate"
        class="error-text"
        >{{ udpateOrDeleteError$ | async }}</span
      >
    </div>
    <div class="template-form__buttons gap-1 cp-mt-2">
      <button class="button-secondary" (click)="cancel()">Cancel</button>
      <button class="button-secondary" (click)="resetForm(isUpdate)">
        Reset
      </button>
      <button
        *ngIf="isUpdate"
        class="button-secondary"
        [disabled]="
          (deleteLoader$ | async) ||
          (form.invalid && !updateForm.get('value').hasError('isSameTemplate'))
        "
        (click)="deleteTemplate()"
      >
        <app-loader
          [buttonLoader]="true"
          *ngIf="deleteLoader$ | async"
        ></app-loader>
        Delete
      </button>
      <button
        (click)="isUpdate ? edit() : create()"
        class="button-primary"
        [disabled]="
          ((createLoader$ | async) && !isUpdate) ||
          ((updateLoader$ | async) && isUpdate) ||
          form.invalid
        "
      >
        <app-loader
          [buttonLoader]="true"
          *ngIf="
            ((createLoader$ | async) && !isUpdate) ||
            ((updateLoader$ | async) && isUpdate)
          "
        ></app-loader>
        {{ isUpdate ? "Save" : "Create" }}
      </button>
    </div>
  </ng-template>
</div>
