import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TemplateFormComponent } from "../template-form/template-form.component";
import * as fromNoteStore from "@notes/store";
import { NoteletInterface } from "@shared-modules/components/quill-editor";
import { Subject } from "rxjs";
import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "cp-save-template",
  templateUrl: "./save-template.component.html",
  styleUrls: ["./save-template.component.scss"],
})
export class SaveTemplateComponent implements OnInit, OnDestroy {
  template: string = "";
  private $unsubscribe = new Subject();
  notelets: NoteletInterface[] = [];
  constructor(private _noteStore: Store<fromNoteStore.NoteFormState>) {}
  public higlight$ = this._noteStore.pipe(
    select(fromNoteStore.highlightSelector)
  );
  ngOnInit(): void {
    this.higlight$.pipe(takeUntil(this.$unsubscribe)).subscribe((template) => {
      this.template = template;
    });

    this.notelets$.pipe(takeUntil(this.$unsubscribe)).subscribe((notelets) => {
      this.notelets = notelets;
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  readonly dialog = inject(MatDialog);

  public unsubscribe$ = new Subject();

  public notelets$ = this._noteStore.pipe(select(fromNoteStore.getAllNotelets));

  openDialog(isUpdate: boolean = false): void {
    this.dialog.open(TemplateFormComponent, {
      width: "40vw",
      data: {
        template: this.template,
        notelets: this.notelets,
        isUpdate,
      },
      autoFocus: false,
      restoreFocus: false,
    });
  }
}
