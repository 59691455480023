import { Pipe, PipeTransform } from "@angular/core";
import { NoteletInterface } from "@shared-modules/components/quill-editor";

@Pipe({
  name: "noteletsDropdown",
})
export class NoteletsDropdownPipe implements PipeTransform {
  transform(notelets: NoteletInterface[]): string[] {
    return notelets
      .map((notelet) => notelet.key)
      .sort((a, b) => a.localeCompare(b));
  }
}
