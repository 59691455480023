import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { skip, takeUntil } from "rxjs/operators";

import * as fromNoteStore from "@notes/store";
import { NoteletInterface } from "@shared-modules/components/quill-editor";
import {
  TemplateFormInterface,
  TemplatePopupInterface,
} from "@notes/models/notes.model";
import { select, Store } from "@ngrx/store";
import { DialogService } from "@iris/service/dialog.service";
import { noteletsNameValidator } from "@notes/validators";
import { isTemplateModifiedValidator } from "@notes/validators/isTemplateModified.validator";

@Component({
  selector: "cp-template-form",
  templateUrl: "./template-form.component.html",
  styleUrls: ["./template-form.component.scss"],
})
export class TemplateFormComponent implements OnInit, AfterViewInit, OnDestroy {
  public htmlText = "";

  public templateControl = new FormControl("");

  public templates: NoteletInterface[] = [];

  private unsubscribe$ = new Subject();

  public createLoader$ = this._noteStore.pipe(
    select(fromNoteStore.noteletCreateLoaderSelector)
  );
  public updateLoader$ = this._noteStore.pipe(
    select(fromNoteStore.noteletUpdateLoaderSelector)
  );
  public deleteLoader$ = this._noteStore.pipe(
    select(fromNoteStore.noteletDeleteLoaderSelector)
  );

  public createError$ = this._noteStore.pipe(
    select(fromNoteStore.noteletCreateErrorSelector)
  );

  public udpateOrDeleteError$ = this._noteStore.pipe(
    select(fromNoteStore.noteletDeleteOrUpdateErrorSelector)
  );

  public success$ = this._noteStore.pipe(
    select(fromNoteStore.noteletAPISuccess)
  );
  deletionId: string = null;

  readonly data = inject<TemplatePopupInterface>(MAT_DIALOG_DATA);
  templatesMap: Map<string, NoteletInterface> = new Map<
    string,
    NoteletInterface
  >();
  template: string = "";
  public getForm(
    data: NoteletInterface = {
      key: "",
      value: "",
    }
  ) {
    return this._fb.group<TemplateFormInterface>({
      key: this._fb.control(data.key),
      value: this._fb.control(data.value, [Validators.required]),
    });
  }

  createForm = this.getForm();
  updateForm = this.getForm();

  constructor(
    private _fb: FormBuilder,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    private _dialogRef: MatDialogRef<TemplateFormComponent>,
    private _dialogService: DialogService,
    private _cdr: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {
    this.setForm();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit(): void {
    this.intializeListeners();
  }

  intializeListeners() {
    this.updateForm
      .get("key")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((key: string) => {
        if (!key) return;
        this.template = this.templatesMap.get(key).value;
        this.updateForm.get("value").setValue(this.template);
        this.updateForm
          .get("value")
          .setValidators([isTemplateModifiedValidator(this.template)]);
        this.updateForm.get("value").updateValueAndValidity();
      });

    this.success$
      .pipe(takeUntil(this.unsubscribe$), skip(1))
      .subscribe((val) => {
        if (val) {
          this._dialogRef.close();
        }
        if (this.deletionId) {
          this.templates = this.templates.filter(
            (el) => el._id != this.deletionId
          );
          this.resetForm();
        }
      });

    this.udpateOrDeleteError$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.deletionId) {
          this.deletionId = null;
        }
      });
  }

  public resetForm(isUpdate: boolean = false): void {
    this.deletionId = null;

    const form = isUpdate ? this.updateForm : this.createForm;

    if (!form) {
      console.error("Form is not initialized");
      return;
    }

    if (isUpdate) {
      form.get("value")?.setValue(this.template || ""); // Avoids undefined errors
    } else {
      form.reset({ key: "", value: "" }); // Resets and ensures default values
    }

    form.markAsUntouched();
  }

  public avoidSpaces(input) {
    this.createForm.get("key").setValue(input.target.value.replace(" ", ""));
  }

  setForm() {
    const { notelets } = this.data;
    this.templates = notelets;
    this.updateForm.get("key").setValidators([Validators.required]);
    this.templatesMap = this.templates.reduce((acc, el) => {
      acc.set(el.key, el);
      return acc;
    }, new Map<string, NoteletInterface>());

    this.createForm = this.getForm({
      key: "",
      value: this.data.template,
    });

    this.createForm
      .get("key")
      .setValidators([
        noteletsNameValidator(this.data.notelets),
        Validators.required,
      ]);

    this._cdr.detectChanges();
  }

  public create() {
    const { key, value } = this.createForm.value;
    const payload = {
      accessType: "user",
      key,
      value,
    };
    this._noteStore.dispatch(
      fromNoteStore.createNotelet({
        payload,
      })
    );
  }

  public edit() {
    const { key, value } = this.updateForm.value;
    this._noteStore.dispatch(
      fromNoteStore.updateNotelet({
        id: this.templatesMap.get(key)._id,
        template: value,
      })
    );
  }

  public deleteTemplate() {
    const dialogRef = this._dialogService.openConfirmDialogue({
      message: "Are you sure you want to delete this notelet?",
      headerText: "Attention",
      buttonText: "Delete",
      secondaryButtonText: "Cancel",
      alertType: "reject",
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          const { key } = this.updateForm.value;
          this._noteStore.dispatch(
            fromNoteStore.deleteNotelet({
              id: this.templatesMap.get(key)._id,
            })
          );
          this.deletionId = this.templatesMap.get(key)._id;
        }
      });
  }

  public cancel() {
    this._dialogRef.close();
  }
}
