import { Time } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { PatientService } from "src/app/services/patient.service";
import { SiblingService } from "src/app/services/sibling.service";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { ActionTypes } from "../../../actions/currPatient";
import * as fromRoot from "../../../reducers";
import { Subject } from "rxjs";
import { DialogService } from "@iris/service/dialog.service";
import * as fromNoteStore from "@notes-view/store";
import { takeUntil } from "rxjs/operators";
import { FinalNotes } from "@notes-view/model/notes-model";
import { highlightIncompleteConsent } from "src/app/store/actions/patient-chart/notes/notes.actions";

@Component({
  selector: "app-discharge-form",
  templateUrl: "./discharge-form.component.html",
  styleUrls: ["./discharge-form.component.scss"],
})
export class DischargeFormComponent implements OnInit, OnDestroy {
  public rDischargeForm: UntypedFormGroup;
  public currentPatient;

  todayDate: Date = new Date(new Date().setSeconds(0, 0));

  public patICUDischargeDate: string;
  public patICUDischargeDisposition: string;
  public patICUDischargeTime: Time;
  public isFormSubmitted: boolean = false;

  minDate: Date | null = null;
  get maxDate(): Date {
    return new Date();
  }

  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData)
  );

  public disableBtn = null;
  public restError = false;
  public serverError: string = "";

  public isBedAssigned = false;
  public isAadhar = true;
  public isAddress = true;
  public isPhone = true;
  public isChronic = true;

  dischargeDisposition: string[] = [
    "Discharged to home/self care as per medical advice",
    "Discharged to home/self care AGAINST medical advice (DAMA)",
    "Discharged to ward/floor",
    "Discharged to Intermediate Care Unit/High Dependency Unit (HDU)",
    "Discharged to other ICU within the same hospital",
    "Discharged to another facility",
    "Discharged to higher centre of care",
    "Death",
  ];

  OPPatientTimestamp: FormControl<Date>;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  public getAllNoteData$ = this._noteStore.pipe(
    select(fromNoteStore.getAllNoteDatas),
    takeUntil(this.unsubscribe$)
  );

  private incompleteConsent: boolean = false;
  private consentFirstTab?: string;
  constructor(
    private fb: UntypedFormBuilder,
    private _siblingService: SiblingService,
    public _patientService: PatientService,
    private store: Store<fromRoot.AppState>,
    public dialogRef: MatDialogRef<DischargeFormComponent>,
    private alertService: AlertService,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    private _dialogService: DialogService
  ) {
    this.rDischargeForm = this.fb.group({
      timestamp: [this.todayDate, Validators.required],
      patICUDischargeDisposition: [null, Validators.required],
      palliative: [null, Validators.required],
    });

    this.OPPatientTimestamp = new FormControl<Date>(this.todayDate, {
      validators: [Validators.required],
    });
  }

  onClose() {
    this.isFormSubmitted = false;
    // this.activeModal.dismiss();
  }
  dischargeTimeError: string = "";
  handleDischargePatient(
    timestamp: string,
    patICUDischargeDisposition?: string,
    palliative?: string
  ) {
    let dischargeTime = new Date(timestamp);

    if (this.minDate > dischargeTime) {
      this.dischargeTimeError =
        "Patient's discharge date cannot be earlier than their admission date";

      return;
    }

    if (this.incompleteConsent) {
      const dialogRef = this._dialogService.openConfirmDialogue({
        message:
          "Upload ID proof and picture of consent giver for consent note, or continue without?",
        headerText: "Incomplete consent",
        buttonText: "Upload",
        secondaryButtonText: "Continue",
        alertType: "accept",
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) {
            this.store.dispatch(
              highlightIncompleteConsent({
                tabType: this.consentFirstTab,
                isInitialState: false,
              })
            );
            this.dialogRef.close();
          } else {
            this.dischargePatient(
              dischargeTime,
              patICUDischargeDisposition,
              palliative
            );
          }
        });
      return;
    } else {
      this.dischargePatient(
        dischargeTime,
        patICUDischargeDisposition,
        palliative
      );
    }
  }

  private dischargePatient(
    dischargeTime: Date,
    patICUDischargeDisposition?: string,
    palliative?: string
  ) {
    this.disableBtn = "discharge";
    const dischargeProps = [
      {
        propName: "ICUDischargeDate",
        value: dischargeTime,
      },
      {
        propName: "ICUDischargeDisposition",
        value: patICUDischargeDisposition || null,
      },
      {
        propName: "isCurrentlyAdmitted",
        value: false,
      },
      {
        propName: "camera",
        value: null,
      },
      {
        propName: "palliative",
        value: palliative || null,
      },
      {
        propName: "oldHospitalName",
        value: this.currentPatient.hospitalName,
      },
      {
        propName: "oldUnitName",
        value: this.currentPatient.unitName,
      },
      {
        propName: "oldBedNo",
        value: this.currentPatient.bedNo,
      },
      {
        propName: "height",
        value: this.currentPatient?.height,
      },
      {
        propName: "heightUnit",
        value: this.currentPatient?.heightUnit,
      },
      {
        propName: "weight",
        value: this.currentPatient?.weight,
      },
      {
        propName: "weightUnit",
        value: this.currentPatient?.weightUnit,
      },
      {
        propName: "age",
        value: this.currentPatient?.age,
      },
      {
        propName: "sex",
        value: this.currentPatient?.sex,
      },
      {
        propName: "unitType",
        value: this.currentPatient.unitType,
      },
    ];

    this._patientService
      .editPatient(
        this.currentPatient.CPMRN,
        this.currentPatient.encounters,
        dischargeProps
      )
      .subscribe(
        (_) => {
          this.store.dispatch({
            type: ActionTypes.set,
            payload: { hospitalName: null, bedNo: null },
          });
          this.isFormSubmitted = true;
          this._siblingService.editPatient(this.currentPatient.CPMRN); // post new message with discharge patient's CPMRN
          this.serverError = "";
          this.disableBtn = null;
          this.dialogRef.close();
          this.alertService.showNotification({
            type: "Success",
            message: "Patient discharged!",
          });
        },
        (error) => {
          console.log("Error!", error);
          this.disableBtn = null;
          this.serverError = error.error.message;
        }
      );
  }

  ngOnInit() {
    this.patientHeader$.subscribe((patient) => {
      if (patient && patient.CPMRN) {
        this.currentPatient = patient;

        this.minDate = new Date(this.currentPatient.ICUAdmitDate);

        if (this.currentPatient.bedNo) {
          this.isBedAssigned = true;
        }

        if (
          this.currentPatient.isolation?.precautionType === "Droplet" &&
          (this.currentPatient.isolation?.reason === "COVID-19 Positive" ||
            this.currentPatient.isolation?.reason === "COVID-19 Suspected")
        ) {
          if (!this.currentPatient.aadhar) {
            this.isAadhar = false;
          }

          if (!this.currentPatient.phone) {
            this.isPhone = false;
          }

          if (
            !this.currentPatient.address ||
            (this.currentPatient.address &&
              (!this.currentPatient.address.line1 ||
                !this.currentPatient.address.pincode ||
                !this.currentPatient.address.city ||
                !this.currentPatient.address.state))
          ) {
            this.isAddress = false;
          }

          if (
            !this.currentPatient.chronic ||
            (this.currentPatient.chronic && !this.currentPatient.chronic.length)
          ) {
            this.isChronic = false;
          }
        }
      }
    });
    this.getAllNoteData$.subscribe((notes) => {
      let incompleteConsent: boolean = false;
      let consentFirstTab: string = "";
      const orderedNotes: Map<string, FinalNotes[]> = new Map();
      orderedNotes.set("signed", []);
      orderedNotes.set("pended", []);
      orderedNotes.set("draft", []);
      notes.forEach((note) => orderedNotes.get(note.pendOrSigned)?.push(note));
      groupNotesForLoop: for (let commonNoteTypes of orderedNotes.values()) {
        for (let i = 0; i < commonNoteTypes.length; ++i)
          if (
            commonNoteTypes[i].noteType?.toLowerCase() === "consent" &&
            commonNoteTypes[i]["incompleteConsent"]
          ) {
            incompleteConsent = true;
            consentFirstTab = commonNoteTypes[i].pendOrSigned;
            break groupNotesForLoop;
          }
      }
      this.incompleteConsent = incompleteConsent;
      this.consentFirstTab = consentFirstTab;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  canDischarge(type: "NonOPPatient" | "OPPatient"): boolean {
    if (type == "OPPatient") {
      return (
        this.currentPatient?.unitType == "OP" &&
        (this.isAadhar || this.isPhone) &&
        this.isAddress &&
        this.isChronic
      );
    }

    return (
      this.isBedAssigned &&
      this.currentPatient?.unitType !== "OP" &&
      (this.isAadhar || this.isPhone) &&
      this.isAddress &&
      this.isChronic
    );
  }
}
