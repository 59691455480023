import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";

import * as fromNoteletsActions from "../actions";
import { NoteService } from "@notes/services/note.service";
import { NoteletInterface } from "@shared-modules/components/quill-editor";
import { AlertService } from "@iris/service/alert.service";

@Injectable()
export class NoteletsEffects {
  constructor(
    private actions$: Actions,
    private _noteService: NoteService,
    private _alertService: AlertService
  ) {}

  public errorMessage = "Something went wrong!";

  /*
   * Get notelet data
   * */
  loadNotelets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNoteletsActions.loadNotelets),
      switchMap(() =>
        this._noteService.getMacros().pipe(
          map((notelets: NoteletInterface[]) =>
            fromNoteletsActions.loadNoteletSuccess({ notelets })
          ),
          catchError((error: any) =>
            of(
              fromNoteletsActions.loadNoteletError({
                error: error?.message || this.errorMessage,
              })
            )
          )
        )
      )
    )
  );

  /*
   * Create notelet data
   * */
  createNotelet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNoteletsActions.createNotelet),
      switchMap(({ payload }) =>
        this._noteService.createMacros(payload).pipe(
          map((notelet: NoteletInterface) => {
            this._alertService.showNotification({
              type: "Success",
              message: "Notelet created successfully",
            });
            return fromNoteletsActions.createNoteletSuccess({ notelet });
          }),
          catchError((error: any) =>
            of(
              fromNoteletsActions.createNoteletError({
                error: error?.error?.message || this.errorMessage,
              })
            )
          )
        )
      )
    )
  );

  /*
   * Update notelet data
   * */
  updateNotelet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNoteletsActions.updateNotelet),
      switchMap(({ template, id }) =>
        this._noteService.updateMacro(template, id).pipe(
          map((notelet: NoteletInterface) => {
            this._alertService.showNotification({
              type: "Success",
              message: "Notelet updated successfully",
            });
            return fromNoteletsActions.updateNoteletSuccess({ notelet });
          }),
          catchError((error: any) =>
            of(
              fromNoteletsActions.updateNoteletError({
                error: error?.error?.message || this.errorMessage,
              })
            )
          )
        )
      )
    )
  );

  /*
   * Delete notelets data
   * */
  deleteNotelet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNoteletsActions.deleteNotelet),
      switchMap(({ id }) =>
        this._noteService.deleteMacro(id).pipe(
          map(() => {
            this._alertService.showNotification({
              type: "Success",
              message: "Notelet deleted successfully",
            });
            return fromNoteletsActions.deleteNoteletSuccess({ id });
          }),
          catchError((error: any) =>
            of(
              fromNoteletsActions.deleteNoteletError({
                error: error?.error?.message || this.errorMessage,
              })
            )
          )
        )
      )
    )
  );
}
