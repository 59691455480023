import { AutocompleteDataInterface } from "../models/quill-editor.model";

export const autocompleteInitialValue: AutocompleteDataInterface = {
  offsetTop: 0,
  offsetLeft: 0,
  top: 0,
  left: 0,
  height: 0,
  clientX: 0,
  clientY: 0,
  sug: "",
  index: 0,
};

export const maxNotelets = 10;
