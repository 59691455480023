import React from "react";
import { Provider } from "react-redux";
import store from "./reactStore/reduxStore";
import ShowIcdModal from "./showIcdModal/showIcdModal";
import RefreshModal from "./refreshModal/refresh-modal";

interface AppProps {
  onClose: () => void;   
  showModalType: "ICD" | "Refresh"; // Add a prop to determine which modal to show
  version?: string;   
}

const App: React.FC<AppProps> = ({ onClose, showModalType, version }) => (
  <Provider store={store}>
    <div>
      {/* Conditionally render based on the modal type */}
      {showModalType === "ICD" && <ShowIcdModal onClose={onClose} />}
      {showModalType === "Refresh" && version && <RefreshModal version={version} />}
    </div>
  </Provider>
);

export default App;
