import {
  ActionReducerMap,
  createFeatureSelector,
  createReducer,
  on,
} from "@ngrx/store";

import * as fromNoteDataType from "./note-data-types.reducers";
import * as fromNotelets from "./notelets.reducers";
import * as fromNoteInputForm from "./note-input-form.reducer";
import * as fromNoteDispatch from "./note-dispatch.reducers";
import * as fromNotePermission from "./note-permission-access.reducer";
import { CTAType, NotePermissions } from "@notes/models/notes.model";
import { setCTA } from "../actions";
import { FinalNotes } from "@notes-view/model/notes-model";
export const NOTE_STATE_NAME = "notes-input-overhaul";

export const initialState: CTAType = null;
export interface NoteFormState {
  noteDataType: fromNoteDataType.NoteTypeState;
  notelets: fromNotelets.NoteletState;
  noteFormContents: FinalNotes;
  noteDispatch: { loaded: boolean; loading: boolean };
  cta: CTAType;
  notePermissions: NotePermissions;
}

export const reducer: ActionReducerMap<NoteFormState> = {
  noteDataType: fromNoteDataType.reducer,
  notelets: fromNotelets.reducer,
  noteFormContents: fromNoteInputForm.reducer,
  noteDispatch: fromNoteDispatch.reducer,
  notePermissions: fromNotePermission.reducer,
  cta: createReducer(
    initialState,
    on(setCTA, (_state, { cta }) => cta)
  ),
};

export const getNoteState =
  createFeatureSelector<NoteFormState>(NOTE_STATE_NAME);
